import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Spinner, Stack } from 'react-bootstrap'
import { useParams, Redirect } from 'react-router-dom'

import CreateWebsite from './CreateWebsite'
import ConfirmWebsiteDeleteModal from './ConfirmWebsiteDeleteModal'
import WebsiteMetricsSection from './WebsiteMetricsSection'
import WebsiteInformationSection from './WebsiteInformationSection'

import { fetchForAuthenticatedUser, fetchWebsiteBuilds } from '../../store/slices/websitesSlice'
import { websiteIsCreated } from '../../helpers/websiteHelper'

const WebsiteHeader = ({ domain }) => (
  <a
    className="text-center page-header"
    href={`https://${domain}/`}
    rel="noreferrer"
    target="_blank"
  >{`${domain}`}</a>
)

const ShowWebsiteContent = ({ website }) => {
  const [ confirmDelete, setConfirmDelete ] = useState(false)
  return (
    <Container>
      <Col>
        <ConfirmWebsiteDeleteModal id={website.id} show={confirmDelete} onHide={() => setConfirmDelete(false)} />
        <WebsiteMetricsSection websiteId={website.id} />
        <WebsiteInformationSection handleDelete={() => setConfirmDelete(true)} />
      </Col>
    </Container>
  )
}

const ShowWebsite = () => {
  const dispatch = useDispatch()
  const { websites } = useSelector(state => state.websites)
  const [ initialized, setInitialized ] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    const initialize = async () => {
      const { payload } = await dispatch(fetchForAuthenticatedUser())
      if (payload.websites && payload.websites.find(w => w.id === id)) {
        await dispatch(fetchWebsiteBuilds(id))
      }
    }
    initialize()
    setInitialized(true)
  }, [id, dispatch])

  const website = websites.find(w => w.id === id)
  if (!initialized) {
    return <Spinner animation="border" size="sm" />
  } else if (website === undefined) {
    return <Redirect to="/dashboard/websites" />
  }

  return (
    <Container className="p-5">
      <Stack gap={5}>
        <WebsiteHeader domain={website.alias} />
        {websiteIsCreated(website) ? <ShowWebsiteContent website={website} /> : <CreateWebsite website={website} />}
      </Stack>
    </Container>
  )
}

export default ShowWebsite
