import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { sendDeleteAssignment, fetchAssignmentForUser, updateAssignmentForUser, postAssignment } from '../../api/assignmentsAPI'

/**
 * Create a new assignment.
 */
export const create = createAsyncThunk(
  'assignments/create',
  async (assignment, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user
    try {
      const response = await postAssignment(assignment, authToken)

      return response.data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      console.error(error)
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

export const deleteAssignment = createAsyncThunk(
  'assignments/deleteAssignment',
  async (assignmentId, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user
    try {
      const response = await sendDeleteAssignment(assignmentId, authToken)

      return response.data
    } catch (error) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

/**
 * Fetch assignments for the authenticated user.
 */
export const fetchForAuthenticatedUser = createAsyncThunk(
  'assignments/fetchForAuthenticatedUser',
  async (userData, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user
    const { id } = userData

    try {
      const response = await fetchAssignmentForUser(id, authToken)
      return response.data.assignments
    } catch (error) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

export const updateForAuthenticatedUser = createAsyncThunk(
  'assignments/updateForAuthenticatedUser',
  async (updatedAssignment, { dispatch, getState, rejectWithValue }) => {
    const { user: { id, authToken } } = getState()

    try {
      const response = await updateAssignmentForUser(id, updatedAssignment, authToken)
      return response.data
    } catch (error) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

/**
 * Redux state slice created with Redux toolkit.
 */
export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState: {
    assignments: [],
    localChanges: [],
    loading: true
  },
  reducers: {
    clearAssignments: (state, action) => ({ ...state, assignments: [], localChanges: [] }),
    setLocalChanges: (state, action) => {
      if (state.localChanges.find(a => !a.id ? !action.payload.id : a.id === action.payload.id)) {
        return { ...state, localChanges: state.localChanges.map(a => a.id === action.payload.id ? action.payload : a) }
      } else
        return { ...state, localChanges: state.localChanges.concat([action.payload]) }
    },
    updateAssignment: (state, action) => ({
      ...state,
      assignments: state.assignments.map(
        assignment => assignment.id === action.payload.id ? { ...action.payload } : assignment) })
  },
  extraReducers: {
    [fetchForAuthenticatedUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),

    [fetchForAuthenticatedUser.fulfilled]: (state, action) => ({
      ...state,
      assignments: action.payload.map(assignment => ({...assignment})),
      loading: false
    }),
    [fetchForAuthenticatedUser.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [create.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [create.fulfilled]: (state, action) => ({
      ...state,
      assignments: state.assignments.concat([action.payload]),
      loading: false
    }),
    [create.rejected]: (state, action) => ({ ...state, loading: false }),
    [deleteAssignment.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteAssignment.fulfilled]: (state, action) => ({
      ...state,
      assignments: state.assignments.filter(a => a.id !== action.payload.id),
      loading: false
    }),
    [deleteAssignment.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [updateForAuthenticatedUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [updateForAuthenticatedUser.fulfilled]: (state, action) => ({
      ...state,
      assignments: state.assignments.map(a => a.id === action.payload.id ? { ...action.payload } : a),
      loading: false
    }),
    [updateForAuthenticatedUser.rejected]: (state, action) => ({
      ...state,
      loading: false
    })
  }
})

export const { clearAssignments, setLocalChanges, setNewAssignment, updateAssignment } = assignmentsSlice.actions

export default assignmentsSlice.reducer
