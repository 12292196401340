import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Stack } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { login, confirmSignUp } from '../../store/slices/userSlice'
import { setShowLoginModal } from '../../store/slices/appSlice.js'

import './LoginForm.scss'

export const VerifyForm = ({ email, onVerified }) => {
  const dispatch = useDispatch()
  const { errors } = useSelector(state => state.user)
  const { t } = useTranslation()

  const handleVerify = async e => {
    e.preventDefault()
    const result = await dispatch(confirmSignUp({ email, confirmationCode: e.target.confirmationCode.value }))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      onVerified()
    }
  }

  return (
    <Form onSubmit={handleVerify}>
      <Stack gap={3}>
        <Form.Group controlId="loginFormEmail">
          <Form.Label>{t("Confirmation Code")}:</Form.Label>
          <Form.Control
            required
            name="confirmationCode"
            //placeholder="confirmationCode"
          />
        </Form.Group>
        {errors.map((message, index) => <Alert key={index} variant="danger">{t(message)}</Alert>)}
        <Button className="login-button" type="submit">{t("Confirm")}</Button>
      </Stack>
    </Form>
  )
}

const LoginForm = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { errors, loading } = useSelector(state => state.user)
  const [ verify, setVerify ] = useState(false)

  const handleLogin = async e => {
    e.preventDefault()
    const result = await dispatch(login({ email: e.target.email.value, password: e.target.password.value }))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      dispatch(setShowLoginModal(false))
    } else if (result.payload === "User is not confirmed.") {
      setVerify(e.target.email.value)
    }
  }

  if (!!verify) {
    return <VerifyForm email={verify} onVerified={() => setVerify(false)} />
  }

  return (
    <Form onSubmit={handleLogin}>
      <Stack gap={3}>
        <Form.Group controlId="loginFormEmail">
          <Form.Label>{t("user-form-email-label")}</Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Email"
          />
        </Form.Group>

        <Form.Group controlId="loginFormPassword">
          <Form.Label>{t("user-password-fields-password")}</Form.Label>
          <Form.Control
            required
            type="password"
            name="password"
            placeholder="Password"
          />
        </Form.Group>

        {errors.map((message, index) => <Alert key={index} variant="danger">{t(message)}</Alert>)}
        <Button className="login-button" disabled={loading} type="submit">{t("login-form-submit-text")}</Button>
      </Stack>
    </Form>
  )
}

export default LoginForm
