import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"
import { Container, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import "./AppFooter.scss"

import { showCookieNotification } from '../../store/slices/appSlice'

const AppFooter = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
      <footer>
        <Row className="footer-row">
          <Col className="footer-row-column">
            <Link onClick={() => dispatch(showCookieNotification())} to="/">{t("privacy")}</Link>
          </Col>
          <Col className="footer-row-column">
            <p className="float-end"><a href="#top">{t("footer-back-to-top")}</a></p>
          </Col>
        </Row>
        <Container className="copyright-container">Copyright © 2025 AnteronWeb</Container>
      </footer>
  )
}

export default AppFooter
