import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'
import { Container } from 'react-bootstrap';
import { Redirect, useParams } from 'react-router-dom'

import UserForm from './UserForm'

const ShowUser = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { errors, loading, ...user } = useSelector(state => state.user)

  if (loading) {
    return null
  } else if (id !== user.id) {
    return <Redirect to="/" />
  }

  return (
    <Container className="p-5">
      <h1 className="text-center">{t("show-user-header")}</h1>
      <UserForm  />
    </Container>
  )
}

export default ShowUser
