import React from 'react'

import WorkExperienceSection from '../../experience/WorkExperienceSection'
import HeroSection from '../../hero/HeroSection'
import ShowCaseSection from '../../showcase/ShowCaseSection'

const FrontPage = () => {
  return (
    <main>
      <HeroSection />
      <ShowCaseSection />
      <WorkExperienceSection />
    </main>
  )
}

export default FrontPage
