import React, { useState } from 'react'
import { Button, Container, Card, Modal, Row, Stack } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import LanguageDropdown from '../lang/LanguageDropdown'

import { acceptCookies } from '../../store/slices/appSlice'

import './CookieNotification.scss'

const CookieDetails = ({ show }) => {
  const { t } = useTranslation()

  if (!show) {
    return null
  }

  return (
    <Container>
      <p>{t("third-party-analytics-google-intro")}</p>
      <ul>
        <li>{t("number-of-website-visitors")}</li>
        <li>{t("visitors-country-of-origin")}</li>
      </ul>
      <p>{t("third-party-analytics-google-outro")}</p>
    </Container>
  )
}

const CookieCard =  () => {
  const [ showDetails, setShowDetails ] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleAccept = (e) => {
    e.preventDefault()
    dispatch(acceptCookies())
  }

  return (
    <Card>
      <Card.Header className="cookie-notification-header">
        <div className="cookie-notification-header-label">{t("cookie-notification-header")}</div>
        <LanguageDropdown />
      </Card.Header>
      <Card.Body className="cookie-notification-body">
        <Stack gap={2}>
          <Container>{t("cookie-notification-brief")}</Container>
          <CookieDetails show={showDetails} />
          <Row>
            <Button
              className="cookie-notification-accept-button"
              onClick={handleAccept}>
              {t("cookie-notification-accept")}
            </Button>
          </Row>
          {!showDetails && (
            <Row className="text-center">
              <Link
                className="cookie-notification-read-privacy-policy"
                onClick={() => setShowDetails(true)}
                to="/">
                {t("cookie-notification-read-privacy-policy")}
              </Link>
            </Row>
          )}
        </Stack>
      </Card.Body>
    </Card>
  )
}

const CookieNotification = () => {
  const { cookieConsent, scrolled } = useSelector(state => state.appControl)
  if (!scrolled || cookieConsent) {
    return null
  }

  return (
    <Modal className="cookie-notification" centered show={!cookieConsent}>
      <CookieCard />
    </Modal>
  )
}

export default CookieNotification
