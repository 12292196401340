import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRefresh } from '@fortawesome/free-solid-svg-icons'

import { fetchWebsiteMetrics } from '../../store/slices/websitesSlice'

import WebsiteMetricsChart from './WebsiteMetricsChart'

const WebsiteMetricsSection = ({ websiteId }) => {
  const dispatch = useDispatch()
  const { metrics, loading } = useSelector(state => state.websites)
  const { t } = useTranslation()

  useEffect(() => {
    if (!metrics.find(w => w.websiteId === websiteId)) {
      dispatch(fetchWebsiteMetrics(websiteId))
    }
  }, [metrics, websiteId, dispatch])

  const handleRefresh = (event) => {
    if (!loading) {
      dispatch(fetchWebsiteMetrics(websiteId))
    }
  }

  const websiteMetrics = metrics.find(w => w.websiteId === websiteId)

  return (
    <Container fluid>
      <h3 className="fw-light text-center">
        {t("website-metrics-header")} {' '}
        <Button variant="link" disabled={loading} onClick={handleRefresh} style={{outline: "none", boxShadow: "none"}}>
          <FontAwesomeIcon className={`${loading ? "fa-spin" : ""} m-auto`} icon={faRefresh} />{' '}
        </Button>
      </h3>
      <Row>
        {websiteMetrics &&
            websiteMetrics.data.map(m => (
                <WebsiteMetricsChart
                  key={m.metricName}
                  title={m.metricName}
                  timestamps={m.timestamps}
                  values={m.values}
                />
            ))
        }
      </Row>
    </Container>
  )
}

export default WebsiteMetricsSection
