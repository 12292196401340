import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux'

import { Nav, Navbar, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import LanguageDropdown from '../lang/LanguageDropdown'

import './AppNav.scss'
import profilePicture from './profile_picture.png'
import githubLogo from './github-mark-white.svg'

const GitHubLink = () =>
  <a
    className="github-link"
    href="https://github.com/AnteronGitHub"
    target="_blank"
    rel="noreferrer">
    <img className="external-profile-logo" src={githubLogo} alt="githubLogo"/>
  </a>

const AppNav = () => {
  const { t } = useTranslation()
  const { authToken } = useSelector(state => state.user)

  return (
    <header className="p-2">
      <Navbar className="p-0" collapseOnSelect expand="sm" data-bs-theme="dark">
        <Navbar.Brand as="div">
          <Link className="brand-link" to="/">
            <img className="brand-logo profile-pic" src={profilePicture} alt="profilePic"/>
            <div className="brand-text">AnteronWeb</div>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse className="app-nav mx-1 p-0 justify-content-between">
          <Nav className="w-100">
            <Stack className="app-nav w-100" direction="horizontal" gap={3}>
              {authToken && (
                <Nav.Link as={Link} to="/dashboard" eventKey="1">{t("dashboard")}</Nav.Link>
              )}
              <a className="nav-link" href="#section-showcase">{t("nav-demos")}</a>
              <a className="nav-link" href="#section-work-experience">{t("nav-experience")}</a>
              <div style={{"flexGrow": "1"}} />
              <GitHubLink />
              <LanguageDropdown />
            </Stack>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default AppNav
