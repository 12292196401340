import React, { useRef } from 'react'
import { useTranslation } from "react-i18next"

import { Container, Row, Col } from 'react-bootstrap'

import WorkExperienceData from './WorkExperienceData.js'
import i18n from '../../lang'

import './WorkExperienceSection.scss'

const options = {
  rootMargin: '-10% 0% -30% 0%'
}

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('show-in-timeline');
    }
  })
}, options)

const WorkExperienceEntryTagList = ({ tagList }) => (
  <ul className="work-experience-entry-taglist">
    {tagList.map((item, i) => <li key={i} className="work-experience-entry-taglist-item">{item}</li>)}
  </ul>
)

const WorkExperienceEntryTechList = ({ techList }) => {
  if (!techList.length) {
    return null
  }

  return (
    <ul className="work-experience-entry-techlist">
      {techList.map((item, i) => <li key={i} className="work-experience-entry-techlist-item">{item}</li>)}
    </ul>
  )
}

const WorkExperienceEntry = ({ employer, title, started, tagList, techList, summary, stories }) => (
  <>
    <Row className="work-experience-entry">
      <Col className="work-experience-timeline-container">
        <svg className="work-experience-timeline-entry" preserveAspectRatio="align">
          <line className="work-experience-timeline-line-head" x1="185" y1="0" x2="185" y2="175" strokeWidth="10"/>
          <line x1="185" y1="175" x2="185" y2="8000" strokeWidth="10"/>
          <circle r="40" cx="185" cy="175" />
          <text x="110" y="175" textAnchor="end" transform="rotate(-45, 110, 175)">{started[i18n.language]}</text>
        </svg>
      </Col>
      <Col className="work-experience-entry-info">
        <div className="work-experience-entry-header">
          <div className="work-experience-entry-employer">{employer}</div>
          <div className="work-experience-entry-title">{title}</div>
          <WorkExperienceEntryTagList tagList={tagList} />
          <WorkExperienceEntryTechList techList={techList} />
          <div className="work-experience-entry-employer-summary">{summary}</div>
        </div>
      </Col>
    </Row>
    {stories.map(({ summary, started, tagList, techList}, i) =>
      <WorkExperienceEntryStory
        key={i}
        summary={summary[i18n.language]}
        started={started[i18n.language]}
        tagList={tagList[i18n.language]}
        techList={techList}
      />)}
  </>
)

const WorkExperienceEntryStory = ({ started, summary, tagList, techList }) => {
  const summaryRef = useRef(0)
  const textRef = useRef(0)
  !!summaryRef.current && observer.observe(summaryRef.current)
  !!textRef.current && observer.observe(textRef.current)
  return (
    <Row className="work-experience-entry">
      <Col className="work-experience-timeline-container">
        <svg className="work-experience-timeline-entry">
          <line x1="185" y1="0" x2="185" y2="145" strokeWidth="10"/>
          <line className="work-experience-timeline-line-tail" x1="185" y1="145" x2="185" y2="4000" strokeWidth="10"/>
          <circle r="20" cx="185" cy="145" />
          <text
            ref={textRef}
            className="work-experience-entry-started"
            x="145"
            y="150"
            textAnchor="end"
            transform="rotate(-45, 145, 150)">
            {started}
          </text>
        </svg>
      </Col>
      <Col ref={summaryRef} className="work-experience-entry-story-info animate-in-timeline">
        <div className="work-experience-entry-summary">{summary}</div>
        <WorkExperienceEntryTagList tagList={tagList} />
        <WorkExperienceEntryTechList techList={techList} />
      </Col>
    </Row>
  )
}

const WorkExperienceTimeLine = () => (
  <Col className="work-experience-entries">
    {WorkExperienceData.map(({ employer, title, started, tagList, techList, summary, stories }, i) => (
      <WorkExperienceEntry
        key={i}
        employer={employer[i18n.language]}
        title={title[i18n.language]}
        started={started}
        tagList={tagList[i18n.language]}
        techList={techList}
        summary={summary[i18n.language]}
        stories={stories}
      />
    ))}
  </Col>
)

const WorkExperienceSection = () => {
  const { t } = useTranslation()
  return (
    <section id="section-work-experience">
      <Container>
        <div className="section-work-experience-header">{t("experience-header")}</div>
        <WorkExperienceTimeLine />
      </Container>
    </section>
  )
}

export default WorkExperienceSection
