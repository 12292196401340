import React from 'react'
import { useTranslation } from "react-i18next"
import { Col, Row } from 'react-bootstrap'

import i18n from '../../lang'

import './HeroSection.scss'

// Removed for now - will be added later once the account functionality is updated...
//const ContactButton = () => (
//  <button className="contact-button">Contact me</button>
//)

const HeroSection = () => {
  const { t } = useTranslation()

  return (
    <section id="section-hero" className="mx-auto p-xl-4 align-content-center justify-content-between">
      <Row>
        <Col className="hero-bullets">
          <h1 className={`hero-header hero-header-${i18n.language}`}>{t("hero-header-1")}</h1>
          <div className={`hero-bullet hero-bullet-1 hero-bullet-1-${i18n.language}`}>{t("hero-bullet-1")}</div>
          <div className={`hero-bullet hero-bullet-2 hero-bullet-2-${i18n.language}`}>{t("hero-bullet-2")}</div>
          <div className={`hero-bullet hero-bullet-3 hero-bullet-3-${i18n.language}`}>{t("hero-bullet-3")}</div>
          <div className={`hero-bullet hero-bullet-4 hero-bullet-4-${i18n.language}`}>{t("hero-bullet-4")}</div>
        </Col>
        <Col className="align-content-end">
          <div className="hero-intro-paragraph hero-intro-paragraph-1">{t("hero-intro-paragraph-1")}</div>
          <div className="hero-intro-paragraph">{t("hero-intro-paragraph-2")}</div>
          <div className="hero-intro-paragraph">{t("hero-intro-paragraph-3")}</div>
        </Col>
      </Row>
    </section>
  )
}

export default HeroSection
