import React from 'react'
import { NavItem, NavLink, Dropdown } from 'react-bootstrap'

import i18n from '../../lang'

import './LanguageDropdown.scss'

const LanguageDropdown = () => {
  const handleLanguageClick = (lng) => (e) => {
    e.preventDefault()
    i18n.changeLanguage(lng)
  }

  return (
    <Dropdown className="language-dropdown" as={NavItem} align="end">
      <Dropdown.Toggle as={NavLink}>{i18n.language}</Dropdown.Toggle>
      <Dropdown.Menu className="language-dropdown-menu">
        {["en", "fi"].map((lang, i) =>
          <Dropdown.Item
            key={i}
            className="language-dropdown-menu-item"
            onClick={handleLanguageClick(lang)}>{lang}</Dropdown.Item>)
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LanguageDropdown
