import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ReactGA from 'react-ga4'

export const showMessage = createAsyncThunk(
  'app/showMessage',
  async (message, { dispatch, rejectWithValue }) => {
    dispatch(addMessage(message))
    setTimeout(() => {
      dispatch(removeMessage(message))
    }, 5000)
  }
)

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    cookieConsent: false,
    scrolled: false,
    readingPrivacyPolicy: false,
    showLoginModal: false,
    showRegisterOrganizationModal: false,
    messages: []
  },
  reducers: {
    init: (state, action) => {
      const cookieConsent = localStorage.getItem("cookieConsent")
      return { ...state, cookieConsent: cookieConsent === "true" }
    },
    recordScroll: (state, action) => ({ ...state, scrolled: true }),
    acceptCookies: (state, action) => {
      localStorage.setItem("cookieConsent", true)

      if (process.env.REACT_APP_G_TAG) {
        ReactGA.initialize(process.env.REACT_APP_G_TAG)
      }

      return { ...state, cookieConsent: true }
    },
    showCookieNotification: (state, action) => ({ ...state, cookieConsent: false }),
    readingPrivacyPage: (state, action) => ({ ...state, readingPrivacyPolicy: action.payload }),
    addMessage: (state, action) => ({ ...state, messages: state.messages.concat([action.payload]) }),
    removeMessage: (state, action) => ({ ...state, messages: state.messages.filter(m => m !== action.payload) }),
    setShowLoginModal: (state, action) => ({ ...state, showLoginModal: action.payload }),
    setShowRegisterOrganizationModal: (state, action) => ({ ...state, showRegisterOrganizationModal: action.payload })
  }
})

export const {
  acceptCookies,
  showCookieNotification,
  addMessage,
  recordScroll,
  removeMessage,
  setShowLoginModal,
  setShowRegisterOrganizationModal,
  readingPrivacyPage
} = appSlice.actions

export default appSlice.reducer
