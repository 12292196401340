import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'
import { Accordion, Button, Form, Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import OrganizationsList from '../organization/OrganizationsList'
import { updateUserAttributes, resetPassword } from '../../store/slices/userSlice'

const UserPasswordResetLink = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const handleClick = e => {
    e.preventDefault()
    dispatch(resetPassword())
  }
  return <Link to="" onClick={handleClick}>{t("user-form-reset-password")}</Link>
}

const UserForm = () => {
  const dispatch = useDispatch()
  const { loading, userAttributes } = useSelector(state => state.user)
  const { organizations } = useSelector(state => state.organizations)
  const { t } = useTranslation()

  const validateForm = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = 'Email required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    return errors
  }

  return (
    <Formik
      initialValues={userAttributes}
      onSubmit={formData => dispatch(updateUserAttributes(formData))}
      validate={validateForm}
    >
      {({
        values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Accordion>
            <Stack gap={5}>
              <Form.Group controlId="accountCredentials">
                <Stack gap={2}>
                  <Form.Label>{t("user-form-email-label")}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    isInvalid={!!touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                    value={values.email}
                    disabled={true}
                  />
                  {errors.email && touched.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                  <UserPasswordResetLink />
                </Stack>
              </Form.Group>
              <Form.Group controlId="userData">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{t("user-form-personal-data-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Label>{t("user-form-first-name-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        isInvalid={!!touched.name && !!errors.name}
                        isValid={touched.name && !errors.name}
                        value={values.name}
                      />
                      <Form.Label>{t("user-form-last-name-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="family_name"
                        onChange={handleChange}
                        isInvalid={!!touched.family_name && !!errors.family_name}
                        isValid={touched.family_name && !errors.family_name}
                        value={values.family_name}
                      />
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              <Form.Group controlId="userFormContact">
                <Accordion.Item eventKey="2">
                  <Accordion.Header>{t("user-form-contact-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Label>{t("user-form-phone-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        onChange={handleChange}
                        isInvalid={!!touched.phone_number && !!errors.phone_number}
                        isValid={touched.phone_number && !errors.phone_number}
                        value={values.phone_number}
                      />
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              {organizations.length > 0 && (
                <Form.Group controlId="userFormOrganizations">
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{t("user-form-affiliations-header")}</Accordion.Header>
                    <Accordion.Body>
                      <OrganizationsList organizations={organizations} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Form.Group>
              )}
              <Button type="submit" disabled={loading}>{t("show-user-submit-text")}</Button>
            </Stack>
          </Accordion>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
