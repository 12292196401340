
const WorkExperienceData = [
  {
    employer: {
      en: "Nordea Bank Finland Abp",
      fi: "Nordea Bank Finland Abp"
    },
    title: {
      en: "Software Developer",
      fi: "Ohjelmistokehittäjä"
    },
    started: {
      en: "Sep. 2016",
      fi: "9. 2016"
    },
    tagList: {
      en: ["#finance", "#payment-systems", "#online-banking"],
      fi: ["#finanssiala", "#maksujarjestelmat", "#verkkopankki"],
    },
    techList: ["Robot Framework", "JavaFX", "CSS", "C++", "Sqlite"],
    summary: {
      en: `
        My first software development work contract was as a consultant in Nordea, the largest bank group in the Nordics.
        I worked in payment technologies, participating in testing and development of payment systems and account
        management software.
      `,
      fi: `
        Ensimmäisessä ohjelmistokehittäjän työpaikassani, työskentelin konsulttina Nordealla, pohjoismaiden suurimmassa
        pankkiyhtymässä. Työskentelin maksuteknologioiden parissa, osallistuen maksujärjestelmien ja
        tilinhallintaohjelmiston testaukseen ja kehitykseen.
      `
    },
    stories: [
      {
        summary: {
          en: `
            In the beginning of my assignment, I completed the ITIL Foundation certificate for agile IT service
            development processes.
          `,
          fi: `
            Toimeksiantoni aluksi suoritin ITIL Foundation -sertifikaatin agiilista IT palveluiden tarjoamisesta.
          `
        },
        started: {
          en: "Sep. 2016",
          fi: "9. 2016"
        },
        tagList: {
          en: ["#agile", "#software-development-processes"],
          fi: ["#agiili", "#ohjelmistokehityksen-prosessit"]
        },
        techList: []
      },
      {
        summary: {
          en: `
            During the first part of my contract, I participated in developing test automation for digital payment
            systems by implementing scripts based on requirement specifications.
          `,
          fi: `
            Toimeksiantoni alkuosassa osallistuin digitaalisten maksujärjestelmien testiautomaation kehitykseen
            implementoimalla testiskriptejä vaatimusmääritysten pohjalta.
          `
        },
        started: {
          en: "Fall 2016",
          fi: "Syksy 2016"
        },
        tagList: {
          en: ["#payment-systems"],
          fi: ["#maksujarjestelmat"]
        },
        techList: ["Robot Framework"]
      },
      {
        summary: {
          en: `
            For the latter part of my contract, I worked on a project, where banking officials' desktop user interfaces
            were modernized to Java based. While updating two such applications, I also developed reusable CSS style
            definitions for commonly used UI elements, that other developers in the same department were able to
            utilize.
          `,
          fi: `
            Jälkimmäisen osan sopimustani työskentelin projektissa, jossa pankkivirkailijoiden käyttämät
            työasemakäyttöliittymät modernisoitiin Javapohjaisiksi. Samalla kun päivitin kahden ohjelman
            käyttöliittymät, kirjoitin myös CSS tyylimääritykset, joita muut kehittäjät voivat hyödyntää yhtenäisemmän
            tyylin takaamiseksi.
          `
        },
        started: {
          en: "Spring 2017",
          fi: "Kevät 2017"
        },
        tagList: {
          en: ["#online-banking"],
          fi: ["#verkkopankki"]
        },
        techList: ["JavaFX", "CSS", "C++", "Sqlite"]
      }
    ]
  },
  {
    employer: {
      en: "Verkkokauppa.com",
      fi: "Verkkokauppa.com"
    },
    title: {
      en: "System Developer",
      fi: "Järjestelmäkehittäjä"
    },
    started: {
      en: "Sep. 2017",
      fi: "9. 2017"
    },
    tagList: {
      en: ["#online-retail", "#web-store", "#warehouse"],
      fi: ["#verkkojalleenmyynti", "#verkkokauppa", "#varasto"],
    },
    techList: ["PHP", "Zend", "JavaScript", "React.js", "Sass", "Hapi", "MariaDB", "MongoDB", "Jest", "Robot Framework"],
    summary: {
      en: `
        In my second software developer job I worked in Verkkokauppa.com, a pioneering online retailer in Finland.
        During my work I developed every part of the IT system.
      `,
      fi: `
        Toisessa ohjelmistokehittäjän työpaikassani olin Verkkokauppa.com:issa, suomalaisen verkkomyynnin pioneerissa.
        Työskentelyni aikana osallistuin jokaiseen IT järjestelmän osa-alueen kehitykseen.
      `
    },
    stories: [
      {
        summary: {
          en: `
            My contract started in team that developed the in-house management software, used by employees
            ranging from warehouse to sales and customer service.
          `,
          fi: `
            Työsopimuseni alkoi tiimissä, jossa kehitettiin in-house toiminnanohjausohjelmistoa, jota käyttävät
            työntekijät varastosta aina myyntiin ja asiakaspalveluun.
          `
        },
        started: {
          en: "Fall 2017",
          fi: "Syksy 2017"
        },
        tagList: {
          en: ["#warehouse"],
          fi: ["#varasto"]
        },
        techList: ["PHP", "Zend", "JavaScript", "MariaDB"]
      },
      {
        summary: {
          en: `
            As the GDPR was to be fully adapted by May 2018, we formed a team of developers to assure that both the
            public and the private IT systems complied with the directive. As part of the team, I implemented scripts for
            extracting and anonymizing user data, scraped from the systems. I also contributed to implementing the
            updated cookie notification and privacy settings available in the public web store.
          `,
          fi: `
            GDPR asetuksen astuessa voimaan Toukokuussa 2018, muodostimme tiimin, jonka vastuulla olisi varmistaa, että
            sekä julkiset, että yksityiset IT järjstelmät noudattaisivat direktiivin vaatimuksia. Osana tiimiä toteutin
            skriptejä asiakastietojen hakemiseen, sekä anonymisoimiseen. Osallistuin myös päivitettyjen evästeiden
            käytön selosteen ja käyttäjän yksityisyysasetusten toteutukseen julkisessa verkkokaupassa.
          `
        },
        started: {
          en: "Spring 2018",
          fi: "Kevät 2018"
        },
        tagList: {
          en: ["#gdpr", "#privacy"],
          fi: ["#gdpr", "#yksityisyys"]
        },
        techList: ["PHP", "Zend", "JavaScript", "React.js", "MariaDB"]
      },
      {
        summary: {
          en: `
            I participated in the continuous development of the public web applications, which included UX
            improvements, and general backend system improvements. As an example of a typical UX improvement, see
            the demo project on product availability UX improvement.
          `,
          fi: `
            Osallistuin julkisen websovelluksen jatkuvaan kehitykseen, mikä sisälsi käyttäjäkokemuksen parannuksia, ja
            yleisiä taustajärjestelmien parannuksia. Esimerkkinä käyttäjäkokemuksen parannuksesta, katso demo projekti
            tuotesaatavuuksien parannuksesta.
          `
        },
        started: {
          en: "Fall 2018",
          fi: "Syksy 2018"
        },
        tagList: {
          en: ["#online-retail", "#web-store"],
          fi: ["#verkkojalleenmyynti", "#verkkokauppa"],
        },
        techList: ["React.js", "Sass", "Hapi", "MongoDB", "Jest", "Robot Framework"]
      },
      {
        summary: {
          en: `
            Work in here as well was done with agile software development techniques, and during my contract also I
            completed ScrumMaster Certificate.
          `,
          fi: `
            Työskentely täälläkin tapahtui agiileilla ohjelmistokehityksen menetelmillä, ja sopimukseni aikana myös
            minä suoritin ScrumMaster sertifikaatin.
          `
        },
        started: {
          en: "Fall 2018",
          fi: "Syksy 2018"
        },
        tagList: {
          en: ["#agile", "#software-development-processes"],
          fi: ["#agiili", "#ohjelmistokehityksen-prosessit"]
        },
        techList: []
      }
    ]
  },
  {
    employer: {
      en: "CSC IT Center For Science",
      fi: "CSC IT Center For Science"
    },
    title: {
      en: "Software Specialist",
      fi: "Ohjelmistoasiantuntija"
    },
    started: {
      en: "Jun. 2020",
      fi: "6. 2020"
    },
    tagList: {
      en: ["#data-platforms", "#scientific-research"],
      fi: ["#data-alustat", "#tieteellinen-tutkimus"]
    },
    techList: ["Python", "Flask", "RabbitMQ", "Sqlite", "JWT"],
    summary: {
      en: `
        CSC provides most of the IT infrastructure used by the Finnish Universities and research institutions. In
        addition to the LUMI super computer, CSC is known as the maintainer Funet, the backbone of the Finnish Internet
        network. I was hired in the DevOps developer team for CSC Fairdata services.
      `,
      fi: `
        CSC tuottaa suurimman osan suomalaisten yliopistojen ja tutkimuslaitosten IT palveluista. LUMI supertietokoneen
        lisäksi CSC tunnetaan Funet-verkosta, joka toimii Suomen Internet-verkon
        runkona. Minut palkattiin CSC:n Fairdata palveluiden DevOps-kehitystiimiin.
      `
    },
    stories: [
      {
        summary: {
          en: `
            My first assigned project was developing file download microservice for Fairdata services. The service
            offers scalable packaging and downloading for datasets housed in the platform, while enforcing
            authorization implemented by the services.
          `,
          fi: `
            Ensimmäisessä minulle osoitetussa projektissa kehitin mikropalvelun tiedostojen lataamiseksi Fairdata
            palveluista. Tiedostolatauspalvelu tarjoaa skaalautuvan datan pakkaus- ja lataustoiminnallisuuden,
            noudattaen palvelujen autorisaatiota.
          `
        },
        started: {
          en: "Summer 2020",
          fi: "Kesä 2020"
        },
        tagList: {
          en: ["#large-file-download"],
          fi: ["#tiedostojen-lataus"]
        },
        techList: ["Python", "Flask", "RabbitMQ", "Sqlite", "JWT"]
      },
      {
        summary: {
          en: `
            I implemented the UI for data visualization tool for atmospheric data. The development had a strict
            deadline, which required good knowledge of the used tools. Overall the project can still be considered
            successful. For more details of the project, see the demos section.
          `,
          fi: `
            Kehitin modernisoidun käyttöliittymän ilmanlaatudatan visualisaatiotyökaluun. Kehityksessä oli
            poikkeuksellisen tiukka aikataulu, vaatien hyvän työkalujen tuntemuksen. Kaiken kaikkiaan projektia voidaan
            kuitenkin pitää onnistuneena. Ks. lisätietoja demot-osiossa.
          `
        },
        started: {
          en: "Fall 2020",
          fi: "Syksy 2020"
        },
        tagList: {
          en: [],
          fi: []
        },
        techList: ["Java", "Quarkus", "React.js", "Redux", "TypeScript", "OpenShift", "PostgreSQL"]
      },
      {
        summary: {
          en: `
            The goal of our DevOps team was to unify the development practices in the Fairdata services by utilizing
            software automation. The code base for the services comprises of multiple microservices. I designed and
            implemented CI/CD solutions automated provisioning of staging environments as well as deployment pipelines.
            Additionally we containerized the development environment, which enables fast environment set up on
            workspaces.
          `,
          fi: `
            DevOps-tiimimme tavoitteena oli yhtenäistää Fairdata palveluiden kehitysprosessit hyödyntäen automaatiota.
            Palveluiden koodikanta on laaja, koostuen useasta mikropalvelusta. Suunnittelin ja toteutin CI/CD
            automaation ohjelmistojen julkaisuun sekä testausympäristöjen provisioimisen automatisoimiseen. Tämän
            lisäksi kontitimme palvelujen kehitysympäristön, mikä mahdollistaa nopean ympäristön asentamisen
            työasemille.
          `
        },
        started: {
          en: "2020-2021",
          fi: "2020-2021"
        },
        tagList: {
          en: ["#devops", "#cicd", "#containerization"],
          fi: ["#devops", "#cicd", "#kontitus"]
        },
        techList: ["Ansible", "OpenStack", "Docker", "RabbitMQ", "Redis", "PostgreSQL"]
      }
    ]
  },
  {
    employer: {
      en: "University of Helsinki",
      fi: "Helsingin Yliopisto"
    },
    title: {
      en: "Doctoral Researcher",
      fi: "Väitöskirjatutkija"
    },
    started: {
      en: "May 2021",
      fi: "5. 2021"
    },
    tagList: {
      en: ["#edge-computing", "#stream-processing-systems", "#deep-learning", "#6g"],
      fi: ["#reunaohjelmointi", "#reaaliaikaisen-prosessointi", "#syvaoppiminen", "#6g"]
    },
    techList: ["Python", "Pytorch", "CUDA", "AsyncIO"],
    summary: {
      en: `
        My doctoral research was conducted as part of the 6G research group in the University of Helsinki. My research
        was in the intersection of system development, Artificial Intelligence and Machine Learning.
      `,
      fi: `
        Olen toteuttanut väitöskirjatutkimukseni osana 6G tutkimusryhmää Helsingin Yliopistolla. Tutkimuseni sisältää
        yhdistelmän järjestelmäkehitystä ja tekoälytutkimusta.
      `
    },
    stories: [
      {
        summary: {
          en: `
            I contributed sections about Network Function Virtualization in the 6G MOOC course provided by the
            University of Helsinki.
          `,
          fi: `
            Kirjoitin osuuden verkkofunktiovirtualisaatiosta Helsingin Yliopiston 6G MOOC verkkokurssille.
          `
        },
        started: {
          en: "Summer 2021",
          fi: "Kesä 2021"
        },
        tagList: {
          en: ["#6g"],
          fi: ["#6g"]
        },
        techList: []
      },
      {
        summary: {
          en: `
            I started developing a custom stream processing cluster software for embedded edge devices. The software
            enables real time distributed data processing. It is optimized for computationally heavy analytical methods
            such as neural networks making it a potential solution for real time video analytics. We use the software
            for system evaluations in my research articles.
          `,
          fi: `
            Aloitin oman sulautetuille reunajärjestelmille suunnitellun klusteriohjelmiston kehityksen. Ohjelmistoni
            mahdollistaa reaaliaikaisen hajautetun datakäsittelyn. Se on optimoitu raskaille analyyttisille
            menetelmille, kuten neuroverkoille GPU-laskennalla, ja se soveltuu esimerkiksi reaaliaikaiseen
            videoanalytiikkaan. Käytämme ohjelmistoa pohjana tutkimukseni kokeellisissa mittauksissa.
          `
        },
        started: {
          en: "Fall 2021",
          fi: "Syksy 2021"
        },
        tagList: {
          en: ["#stream-processing", "#deep-learning", "#computer-vision"],
          fi: ["#striimi-prosessointi", "#syvaoppiminen", "#konenako"]
        },
        techList: ["Python", "AsyncIO", "Pytorch", "CUDA", "NVIDIA Jetson"]
      },
      {
        summary: {
          en: `
            I participated in first 6G Summit in Abu Dhabi to present a poster of my reseach in Information Theory.
          `,
          fi: `
            Osallistuin ensimmäiseen 6G Summit -tapahtumaan Abu Dhabissa, jossa esittelin tutkimustani
            informaatioteoriasta julistesessiossa.
          `
        },
        started: {
          en: "Oct. 2022",
          fi: "10. 2022"
        },
        tagList: {
          en: ["#6g"],
          fi: ["#6g"]
        },
        techList: []
      },
      {
        summary: {
          en: `
            I participated in the IEEE 7th International Conference on Fog and Edge Computing (ICFEC) to present my
            article "Fog Computing for Deep Learning with Pipelines".
          `,
          fi: `
            Osallistuin IEEE 7th International Conference on Fog and Edge Computing (ICFEC) -konferenssiin, jossa
            esittelin tutkimusartikkelini "Fog Computing for Deep Learning with Pipelines".
          `
        },
        started: {
          en: "May 2023",
          fi: "5. 2023"
        },
        tagList: {
          en: [],
          fi: []
        },
        techList: []
      },
      {
        summary: {
          en: `
            I participated in the IEEE 9th International Conference on Fog and Mobile Edge Computing (FMEC) to present my
            article "Flow Control Solution to Avoid Bottlenecks in Edge Computing for Video Analytics".
          `,
          fi: `
            Osallistuin IEEE 9th International Conference on Fog and Mobile Edge Computing (FMEC), jossa esittelin
            tutkimusartikkelini "Flow Control Solution to Avoid Bottlenecks in Edge Computing for Video Analytics".
          `
        },
        started: {
          en: "Sep. 2024",
          fi: "9. 2024"
        },
        tagList: {
          en: [],
          fi: []
        },
        techList: []
      }
    ]
  },
]

export default WorkExperienceData
