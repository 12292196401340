
const translationEn = {
  "nav-demos": "Demos",
  "nav-experience": "Experience",
  "hero-header-1": "Full Stack Software Developer",
  "hero-bullet-1": "Over 8 years of professional experience",
  "hero-bullet-2": "UIs, Servers, databases, and system automation",
  "hero-bullet-3": "IEEE and ACM published",
  "hero-bullet-4": "Finance, Retail, and Scientific Research",
  "hero-intro-paragraph-1": `
    Hi, I am Antero, a full stack software developer.
  `,
  "hero-intro-paragraph-2": `
    With over eight years of professional experience in Internet-based services, I have participated in development,
    testing, and administration.
  `,
  "hero-intro-paragraph-3": `
    Welcome to my porfolio website, where you can keep reading more about my work.
  `,
  "showcase-header": "Projects and Demos",
  "experience-header": "Work Experience",
  "privacy": "Privacy",
  "terms-of-service": "Terms of Service",
  "dashboard": "Dashboard",
  "news": "News",
  "cookie-notification-header": "Use of cookies",
  "cookie-notification-brief": "This site uses cookies to monitor general usage statistics.",
  "cookie-notification-accept": "Accept cookies",
  "cookie-notification-read-privacy-policy": "Read more",
  "privacy-policy-header": "Privacy Policy",
  "privacy-policy-intro": "In order to provide the service, AnteronWeb collects and processes data. The website data is stored in Sweden. This page describes the use of personal information in providing the service.",
  "privacy-policy-personal-info-header": "Personal Information",
  "privacy-policy-personal-info-body": "When registering into the service, the user must provide a valid e-mail address. We treat this e-mail as personal information. Furthermore, the user may provide the following personal data:",
  "e-mail-address": "E-mail address",
  "first-and-or-last-name": "First and/or last name",
  "phone-number": "Phone number",
  "third-party-analytics-header": "Third Party Analytics",
  "third-party-analytics-google-header": "Google Analytics",
  "third-party-analytics-google-intro": "The website uses Google Analytics to collect the following statistics:",
  "third-party-analytics-google-outro": "The collected data is not used for marketing purposes, and it is not shared with third parties.",
  "number-of-website-visitors": "Number of website visitors",
  "visitors-country-of-origin": "Website visitors' country of origin",
  "users-rights-header": "User's Rights",
  "users-rights-body": "Each website user has full control of all the data that they submit through the website.",
  "users-rights-delete-pii-header": "Delete Personal Information",
  "users-rights-delete-pii-body": "At any time, the user can delete his/her personal information.",
  "third-party-analytics-intro": "The website uses the following third party web analytics services.",
  "footer-back-to-top": "Back to top",
  "front-header": "Full Stack Web Development",
  "front-welcome-2": "We are an independent IT consulting company located in Finland. We provide consulting and resources for businesses that provide services on the Internet. Our development and hosting services fit for both small and large businesses. Spanning from Front End to Back End to Administration we can support software development projects of various kinds.",
  "front-welcome-create-assignment": "Create an assignment",
  "front-welcome-login-register-text": "to create a consulting assignment.",
  "front-welcome-create-text": "and we will contact you.",
  "blog-read-more": "Read more",
  "blog-machine-learning-header": "Machine Learning at the Edge",
  "blog-machine-learning-brief": "The Internet for data processing.",
  "blog-automation-header": "Ansible for Openstack administration",
  "blog-automation-brief": "Automation is crucial in modern software administration.",
  "blog-ci-header": "Gitlab CI for Sign-In service",
  "blog-ci-brief": "Continuous Integration / Continuous Delivery.",
  "assignments": "Assignments",
  "organizations": "Organizations",
  "account": "Account",
  "show-assignment-submit-text": "Update Assignment",
  "create-assignment-submit-text": "Create Assignment",
  "assignment-list-no-assignments": "You have not created any assignments.",
  "assignments-page-fetching": "Fetching assignments",
  "assignment-list-create": "Create assignment",
  "assignment-form-title-placeholder": "Assignment Title",
  "assignment-form-client-label": "Client:",
  "assignment-form-client-select-index": "Select organization...",
  "assignment-form-client-select-register": "Register organization...",
  "assignment-form-description-label": "Description:",
  "assignment-form-schedule-header": "Schedule",
  "assignment-form-start-date-label": "Start Date:",
  "assignment-form-end-date-label": "End Date:",
  "assignment-form-additional-data-header": "Additional Data",
  "assignment-form-key-technologies-label": "Key technologies:",
  "assignment-form-business-domain-label": "Business domain:",
  "assignment-form-external-links-label": "External Links:",
  "assignment-form-manage-label": "Manage",
  "assignment-form-manage-delete-label": "Delete Assignment",
  "assignment-form-error-title-required": "Title is required",
  "assignment-form-error-client-required": "Registered client organization is required",
  "assignment-form-error-end-date-before-start": "End date cannot be before start date",
  "organizations-page-fetching": "Fetching organizations",
  "organization": "Organization",
  "organizations-list-register": "Register organization",
  "organizations-list-no-organizations": "You are not associated to any organizations.",
  "organization-form-name-label": "Name:",
  "organization-form-name-placeholder": "Organization Name",
  "organization-form-vatid-label": "VAT ID:",
  "organization-form-vatid-placeholder": "Organization VAT ID",
  "organization-form-management-label": "Management",
  "organization-form-management-delete-label": "Delete",
  "show-organization-submit-text": "Update Organization",
  "create-organization-submit-text": "Register Organization",
  "show-user-header": "User Account",
  "show-user-submit-text": "Update",
  "user-form-reset-password": "Reset password",
  "user-form-email-label": "Email:",
  "user-form-personal-data-header": "Personal Data",
  "user-form-first-name-label": "First Name:",
  "user-form-last-name-label": "Last Name:",
  "user-form-contact-header": "Contact",
  "user-form-phone-label": "Phone number",
  "user-form-affiliations-header": "Company Affiliations",
  "user-form-register": "Register",
  "user-dropdown-logout": "Logout",
  "user-dropdown-login-register": "Login",
  "front-welcome-login-register": "Login or register",
  "user-password-reset-header": "Reset Password",
  "user-password-reset-submit-text": "Reset",
  "user-password-fields-password": "Password:",
  "user-password-fields-confirm-password": "Confirm Password:",
  "message-password-changed": "Password has been changed.",
  "message-email-verification-sent": "Verification link has been sent to your e mail address.",
  "message-email-verified": "Your email address has been verified",
  "message-password-link-sent": "Password reset link has been sent to your email",
  "login-modal-login": "Login",
  "login-modal-register": "Register",
  "login-form-submit-text": "Login",
  "websites": "Websites",
  "websites-header": "Websites",
  "website-form-name-placeholder": "Website name",
  "website-form-create-submit-text": "Create Website",
  "website-form-domainname-label": "Domain name",
  "website-form-error-name-required": "Website name is required",
  "websites-create-link": "Create Website",
  "website-list-no-assignments": "You have not created any websites.",
  "carousel-hosting-header": "From web administration to development",
  "carousel-hosting-brief": "We offer web hosting and development as a service.",
  "carousel-consulting-header": "Pay as you go",
  "carousel-consulting-brief": "For hosting, pay only for the amount of data downloaded from your website.",
  "service-cards-hosting-header": "Website Hosting",
  "service-cards-hosting-title": "No starting fee",
  "service-cards-hosting-gb": "GB",
  "service-cards-hosting-li-1": "Publish website in minutes.",
  "service-cards-hosting-li-2": "Monitor site usage.",
  "service-cards-hosting-li-3": "Pay only for site downloads.",
  "service-cards-consulting-header": "Software Consulting",
  "service-cards-consulting-hour": "hour",
  "service-cards-consulting-li-1": "We help with planning.",
  "service-cards-consulting-li-2": "Starting from 1 hour assignments.",
  "service-cards-consulting-li-3": "Also on-premise consulting.",
  "service-cards-consulting-create": "Plan Assignment",
  "nav-services": "Services",
  "nav-hosting": "Hosting",
  "nav-consulting": "Consulting",
  "nav-about": "About us",
  "overview-header": "Website for Free",
  "overview-body": "With our web platform you can publish your website quickly without large investments. This way you can consolidate your money to developing features such as customer interaction, search engine optimization, or back end systems.",
  "payasyougo-header": "Pay as you Go",
  "payasyougo-body": "With Pay-as-you-go model you avoid large initial investments for creating a website.",
  "developagile-header": "Develop Agile",
  "developagile-body": "By using modern tools, it is possible to create a simple website without expert knowledge. By building your website yourself, you make sure that the web page fits for your company.",
  "getsupport-header": "Support to Reduce Costs",
  "getsupport-body": "In addition to our paid services we provide support by sharing the latest news in website development. Maksullisten palvelujemme lisäksi tarjoamme tukea jakamalla viimeisimpiä uutisia verkkopalvelujen kehityksestä.",
  "website-status-header": "Site",
  "website-status-distribution-status-label": "Status:",
  "website-status-distribution-updated-label": "Updated:",
  "website-status-build-status-label": "Build Status:",
  "Deployed": "Deployed",
  "InProgress": "In Progress",
  "SUCCEEDED": "Succeeded",
  "IN_PROGRESS": "In Progress",
  "website-status-build-started-label": "Build Started:",
  "website-metrics-header": "Use Statistics",
  "BytesDownloaded": "Downloaded Data",
  "Requests": "Requests",
  "website-metrics-total": "total",
  "unit-bytes": "B",
  "website-information-header": "Website Information",
  "website-information-management-header": "Website Management",
  "website-information-management-delete-text": "Delete Website",
  "confirm-website-delete-header": "Confirm delete",
  "confirm-website-delete-confirmation": "Are you sure that you want to delete this website?",
  "confirm-website-delete-disclaimer": "This action cannot be cancelled.",
  "website-form-title": "Create Website",
  "website-form-information-header": "Website Information",
  "website-form-information-domain-label": "Domain:",
  "website-form-information-domain-placeholder": "Website Name",
  "website-form-information-sources-header": "Website Sources",
  "website-form-information-sources-zip-label": "ZIP archive:",
  "hosting-page-title": "Hosting",
  "hosting-page-overview": "We provide hosting for highly-available public websites. This page describes our hosting service.",
  "hosting-page-step2-header": "Register and Bring Your Code",
  "hosting-page-step1-pt-1": "We provide consulting service for creating websites. Alternatively you may use one of the free online tools, like",
  "hosting-page-step1-pt-2": ", to create a new React application, and export its sources as a ZIP file.",
  "hosting-page-step2-pt-1": "After you have created your application, you can register to our web service to upload the application sources as a ZIP file. After uploading your application, the site should be published within 10 minutes.",
  "hosting-page-pricing-header": "Pricing",
  "hosting-page-pricing-body": "Our service pricing is based on costs of maintaining the website. We may use cloud based services to ensure that your website can handle even large amounts of requests:",
  "hosting-pricing-table-cost-header": "Cost",
  "hosting-pricing-table-price-header": "Price",
  "hosting-pricing-table-downloads-label": "Downloads",
  "hosting-pricing-table-code-build-label": "Code Builds",
  "hosting-pricing-table-storage-label": "Storage",
  "consulting-page-title": "Consulting",
  "unit-hour": "hour",
  "consulting-pricing-table-consulting-label": "Consulting",
  "hosting-page-getting-started-header": "Getting Started",
  "hosting-page-getting-started-intro": "Follow the steps below to start using our website hosting service.",
  "hosting-page-step1-header": "Develop Your Application",
  "overview-opportunities-item-1": "Get listed in search engines",
  "overview-opportunities-item-2": "Build your services online",
  "overview-opportunities-item-3": "Integrate to social media",
  "unit-month": "mo",
  "or": "or",
  "websitesources-form-create-submit-text": "Upload sources"
}

const translationFi = {
  "nav-demos": "Demot",
  "nav-experience": "Kokemus",
  "hero-header-1": "Täyden Pinon Ohjelmistokehittäjä",
  "hero-bullet-1": "Yli 8 vuotta ammattikokemusta",
  "hero-bullet-2": "Käyttöliittymät, palvelimet, tietokannat, ja järjestelmäautomaatio",
  "hero-bullet-3": "IEEE ja ACM julkaistu",
  "hero-bullet-4": "Finanssiala, Jälleenmyynti, ja Tieteellinen Tutkimus",
  "hero-intro-paragraph-1": `
    Hei, olen Antero, täyden pinon ohjelmistokehittäjä.
  `,
  "hero-intro-paragraph-2": `
    Yli kahdeksan vuoden työkokemukseni aikana olen osallistunut Internet-pohjaisten palveluiden kehitykseen,
    testaukseen, ja ylläpitoon.
  `,
  "hero-intro-paragraph-3": `
    Tervetuloa portfoliosivulleni, missä voit lukea työstäni enemmän.
  `,
  "showcase-header": "Projektit ja Demot",
  "experience-header": "Työkokemus",
  "privacy": "Tietosuoja",
  "terms-of-service": "Palveluehdot",
  "dashboard": "Tilitoiminnot",
  "news": "Uutiset",
  "cookie-notification-header": "Evästeiden käyttö",
  "cookie-notification-brief": "Tämä sivusto käyttää evästeitä yleisten käyttötilastojen seuraamiseen.",
  "cookie-notification-accept": "Hyväksy evästeet",
  "cookie-notification-read-privacy-policy": "Lue lisää",
  "privacy-policy-header": "Tietosuojaseloste",
  "privacy-policy-intro": "Tuottaakseen palveluitaan, AnteronWeb kerää ja käsittelee tietoja. Tietojen säilytysmaa on Ruotsi. Tämä sivu kuvaa käyttäjän henkilökohtaisten tietojen käyttöä palvelun tuottamisessa.",
  "privacy-policy-personal-info-header": "Yksilöivä Tieto",
  "privacy-policy-personal-info-body": "Kirjautuessaan palveluun, käyttäjän pitää kertoa hänen sähköpostiosoitteensa. Käsittelemme sähköpostiosoitetta yksilöivänä tietona. Lisäksi käyttäjä voi halutessaan kirjata palveluun seuraavat tiedot:",
  "e-mail-address": "Sähköpostiosoite",
  "first-and-or-last-name": "Etu- ja/tai sukunimi",
  "phone-number": "Puhelinnumero",
  "third-party-analytics-header": "Kolmannen Osapuolen Analytiikka",
  "third-party-analytics-google-header": "Google Analytics",
  "third-party-analytics-google-intro": "Sivusto käyttää Google analytiikka (\"Google Analytics\") -palvelua seuraavien tietojen keräämiseen:",
  "third-party-analytics-google-outro": "Kerättyjä tietoja ei käytetä markkinointiin, eikä niitä jaeta kolmansille osapuolille.",
  "number-of-website-visitors": "Sivuston vierailijamäärä",
  "visitors-country-of-origin": "Sivustovierailijoiden kotimaa",
  "users-rights-header": "Käyttäjän Oikeudet",
  "users-rights-body": "Jokaisella sivuston käyttäjällä on täysi valta luovuttamiensa tietojen hallintaan.",
  "users-rights-delete-pii-header": "Yksilöivän Tiedon Poisto",
  "users-rights-delete-pii-body": "Käyttäjä voi poistaa omia tietoja järjestelmästämme koska vain.",
  "third-party-analytics-intro": "Verkkosivustomme käyttää seuraavia kolmannen osapuolen analytiikkapalveluita.",
  "footer-back-to-top": "Sivun alkuun",
  "front-header": "Täyden Pinon Verkkokehitys",
  "front-welcome-2": "Olemme itsenäinen suomalainen IT konsultointiyritys. Tarjoamme konsultointia ja teknistä tukea yritykselle, jotka tarjoavat palveluitaan Internetin välityksellä. Kehitys- ja ylläpitopalvelumme sopivat kaikkiin verkkomarkkinoinnin tarpeisiin aina verkkosivujen ulkoasusta taustajärjestelmien ylläpito- ja kehityshankkeisiin. Palvelukokonaisuutemme soveltuvat sekä pienyritysten pienimuotoisiin tarpeisiin, että suurempien organisaatioiden ohjelmistokehitysprojekteihin.",
  "front-welcome-create-assignment": "Luo konsulttitoimeksianto",
  "front-welcome-login-register-text": "luodaksesi konsulttitoimeksiannon.",
  "front-welcome-create-text": "niin otamme sinuun yhteyttä.",
  "blog-read-more": "Lue lisää",
  "blog-machine-learning-header": "Koneoppiminen Reunalla",
  "blog-machine-learning-brief": "Internet tietojenkäsittelylle.",
  "blog-automation-header": "Automaatio palvelujen ylläpidossa",
  "blog-automation-brief": "Automaation hyödyntäminen laskee palvelujen tuottamisen kustannuksia.",
  "blog-ci-header": "Jatkuva integraatio sovelluskehityksessä",
  "blog-ci-brief": "Automaatio kehityksessä laskee kehityskustannuksia.",
  "assignments": "Toimeksiannot",
  "organizations": "Organisaatiot",
  "account": "Käyttäjätili",
  "show-assignment-submit-text": "Päivitä Toimeksianto",
  "create-assignment-submit-text": "Luo Toimeksianto",
  "assignment-list-no-assignments": "Et ole luonut toimeksiantoja.",
  "assignments-page-fetching": "Haetaan toimeksiantoja",
  "assignment-list-create": "Luo toimeksianto",
  "assignment-form-title-placeholder": "Toimeksiannon otsikko",
  "assignment-form-client-label": "Asiakas:",
  "assignment-form-client-select-index": "Valitse organisaatio...",
  "assignment-form-client-select-register": "Rekisteröi organisaatio...",
  "assignment-form-description-label": "Kuvaus:",
  "assignment-form-schedule-header": "Aikataulu",
  "assignment-form-start-date-label": "Aloituspäivämäärä:",
  "assignment-form-end-date-label": "Lopetuspäivämäärä:",
  "assignment-form-additional-data-header": "Lisätiedot",
  "assignment-form-key-technologies-label": "Avainteknologiat:",
  "assignment-form-business-domain-label": "Liiketoimiala:",
  "assignment-form-external-links-label": "Ulkoiset Linkit:",
  "assignment-form-manage-label": "Hallitse",
  "assignment-form-manage-delete-label": "Poista Toimeksianto",
  "assignment-form-error-title-required": "Otsikko vaaditaan",
  "assignment-form-error-client-required": "Rekisteröity asiakasorganisaatio vaaditaan",
  "assignment-form-error-end-date-before-start": "Lopetuspäivämäärä ei voi olla ennen aloitusta",
  "organizations-page-fetching": "Haetaan organisaatioita",
  "organization": "Organisaatio",
  "organizations-list-register": "Rekisteröi organisaatio",
  "organizations-list-no-organizations": "Tiliäsi ei ole liitetty yhteenkään organisaatioon.",
  "organization-form-name-label": "Nimi:",
  "organization-form-name-placeholder": "Organisaation nimi",
  "organization-form-vatid-label": "Y-tunnus:",
  "organization-form-vatid-placeholder": "Organisaation y-tunnus",
  "organization-form-management-label": "Hallinta",
  "organization-form-management-delete-label": "Poista",
  "show-organization-submit-text": "Päivitä Organisaatio",
  "create-organization-submit-text": "Rekisteröi Organisaatio",
  "show-user-header": "Käyttäjätili",
  "show-user-submit-text": "Päivitä",
  "user-form-reset-password": "Vaihda salasanaa",
  "user-form-email-label": "Sähköposti:",
  "user-form-personal-data-header": "Henkilötiedot",
  "user-form-first-name-label": "Etunimi:",
  "user-form-last-name-label": "Sukunimi:",
  "user-form-contact-header": "Yhteystiedot",
  "user-form-phone-label": "Puhelinnumero",
  "user-form-affiliations-header": "Yritysyhteydet",
  "user-dropdown-logout": "Kirjaudu ulos",
  "user-dropdown-login-register": "Kirjaudu / Rekisteröidy",
  "front-welcome-login-register": "Kirjaudu tai Rekisteröidy",
  "user-password-reset-header": "Vaihda Salasanaa",
  "user-password-reset-submit-text": "Vaihda",
  "user-password-fields-password": "Salasana:",
  "user-password-fields-confirm-password": "Vahvista Salasana:",
  "message-password-changed": "Salasana on vaihdettu.",
  "message-email-verification-sent": "Verifikaatiolinkki on lähetetty sähköpostiosoitteeseesi.",
  "message-email-verified": "Sähköpostiosoitteesi on verifioitu.",
  "message-password-link-sent": "Salasanan vaihtolinkki on lähetetty sähköpostiosoitteeseesi.",
  "login-modal-login": "Kirjaudu",
  "login-modal-register": "Rekisteröidy",
  "login-form-submit-text": "Kirjaudu",
  "user-form-register": "Rekisteröidy",
  "Invalid credentials": "Salasana ei täsmää",
  "websites": "Verkkosivut",
  "websites-header": "Verkkosivut",
  "website-form-name-placeholder": "Verkkosivun nimi",
  "website-form-create-submit-text": "Tee Verkkosivut",
  "website-form-domainname-label": "Domainnimi",
  "website-form-error-name-required": "Verkkosivuston nimi vaaditaan",
  "websites-create-link": "Tee verkkosivut",
  "website-list-no-assignments": "Et ole luonut yhtään verkkosivuja.",
  "carousel-hosting-header": "Kaikkea ylläpidosta kehitykseen",
  "carousel-hosting-brief": "Tarjoamme verkkosivujen ylläpitoa ja kehitystä palveluna.",
  "carousel-consulting-header": "Maksa vain käytöstä",
  "carousel-consulting-brief": "Maksa vain verkkosivuiltasi ladatusta datasta.",
  "nav-services": "Palvelut",
  "nav-hosting": "Ylläpito",
  "nav-consulting": "Konsultointi",
  "nav-about": "Yritys",
  "service-cards-hosting-header": "Verkkosivujen ylläpito",
  "service-cards-hosting-gb": "Gt",
  "service-cards-hosting-li-1": "Julkaise 10 minuutissa.",
  "service-cards-hosting-li-2": "Seuraa sivuston käyttöä.",
  "service-cards-hosting-li-3": "Datapohjainen tai kiinteä veloitus.",
  "service-cards-consulting-header": "Ohjelmistokonsultointi",
  "service-cards-consulting-hour": "tunti",
  "service-cards-consulting-li-1": "Autamme suunnittelussa.",
  "service-cards-consulting-li-2": "Alk. 1 tunnin toimeksiannoista.",
  "service-cards-consulting-li-3": "Myös on-premise konsultointia.",
  "service-cards-consulting-create": "Luo Toimeksianto",
  "overview-header": "Verkkosivut Ilmaiseksi",
  "overview-body": "Verkkoalustamme avulla avulla saat verkkosivut pystyyn minuuteissa ilman alkuinvestaatioita. Täten voit keskittää rahasi toiminnallisuuksien, kuten asiakasinteraktion, hakukoneoptimoinnin, tai taustajärjestelmien kehitykseen.",
  "payasyougo-header": "Maksa vain käytöstä",
  "payasyougo-body": "Maksamalla vain käytöstä lasket kynnystä verkkosivujen aloittamiselle.",
  "developagile-header": "Kehitä Ketterästi",
  "developagile-body": "Moderneilla työkaluilla on mahdollisesta tehdä yksinkertaiset verkkosivut ilman erityisosaamista. Tekemällä verkkosivusi itse, vältät kustannuksia, ja takaat, että sivusi sopivat juuri sinun yrityksellesi.",
  "getsupport-header": "Opastusta kulujen välttämiseksi",
  "getsupport-body": "Maksullisten palvelujemme lisäksi tarjoamme tukea jakamalla viimeisimpiä uutisia verkkopalvelujen kehityksestä.",
  "website-status-header": "Sovellus",
  "website-status-distribution-status-label": "Tila:",
  "Deployed": "Julkaistu",
  "InProgress": "Julkaistaan",
  "SUCCEEDED": "Onnistunut",
  "IN_PROGRESS": "Rakennetaan",
  "website-status-distribution-updated-label": "Lähdekoodi ladattu:",
  "website-status-build-status-label": "Sovelluksen käännös:",
  "website-status-build-started-label": "Käännös aloitettu:",
  "website-metrics-header": "Käyttötilastot",
  "Requests": "Pyynnöt",
  "website-metrics-total": "yhteensä",
  "BytesDownloaded": "Ladattu data",
  "unit-bytes": "t",
  "website-information-header": "Sivuston tiedot",
  "website-information-management-header": "Sivuston Hallinta",
  "website-information-management-delete-text": "Poista Verkkosivu",
  "confirm-website-delete-header": "Vahvista verkkosivun poisto",
  "confirm-website-delete-confirmation": "Haluatko varmasti poistaa tämän verkkosivuston?",
  "confirm-website-delete-disclaimer": "Tätä toimenpidettä ei voi perua.",
  "website-form-title": "Tee Verkkosivut",
  "website-form-information-header": "Verkkosivun Tiedot",
  "website-form-information-domain-label": "Verkko-osoite:",
  "website-form-information-domain-placeholder": "Sivuston Nimi",
  "website-form-information-sources-header": "Verkkosivun Lähdekoodi",
  "website-form-information-sources-zip-label": "ZIP arkisto:",
  "hosting-page-title": "Ylläpitopalvelu",
  "hosting-page-overview": "AnteronWeb.com tarjoaa ylläpitopalvelua julkisille verkkosivuille. Käytämme pilvipohjaisia palveluita, jotta takaamme verkkosivustosi kestävän suuriakin pyyntömääriä. Tämä sivu kuvaa ylläpitopalveluamme.",
  "hosting-page-step2-header": "Rekisteröidy ja Tuo Koodisi",
  "hosting-page-step1-pt-1": "Tarjoamme konsultointia verkkosivujen suunnitteluun. Vaihtoehtoisesti voit käyttää ilmaisia verkkotyökaluja, kuten",
  "hosting-page-step1-pt-2": ", luodaksesi React sovelluksen, jonka lähdekoodin saat ZIP pakattuna.",
  "hosting-page-step2-pt-1": "Luotuasi sovelluksen, voit rekisteröityä verkkopalveluumme ladataksesi sovelluksesi lähdekoodin. Ladattua sovelluksesi, sivustosi pitäisi olla saatavilla noin kymmenen minuutin kuluessa.",
  "hosting-page-pricing-header": "Hinnoittelu",
  "hosting-page-pricing-body": "Palveluhinnoittelumme perustuu verkkosivun ylläpitokustannuksiin. Pidätämme oikeuden hinnoittelun muutoksiin, ilmoitamme muutoksista asiakkaille ainakin kuukauden varoitusajalla.",
  "hosting-pricing-table-cost-header": "Kulu",
  "hosting-pricing-table-price-header": "Hinta",
  "hosting-pricing-table-downloads-label": "Sivustolataukset",
  "hosting-pricing-table-code-build-label": "Koodin Rakennus",
  "hosting-pricing-table-storage-label": "Säilytys",
  "consulting-page-title": "Ohjelmistokonsultointi",
  "unit-hour": "tunti",
  "consulting-pricing-table-consulting-label": "Konsultointi",
  "hosting-page-getting-started-header": "Aloittaminen",
  "hosting-page-getting-started-intro": "Noudata alla olevia vaiheita alkaaksesi käyttää ylläpitopalveluamme.",
  "hosting-page-step1-header": "Kehitä Sovelluksesi",
  "overview-opportunities-item-1": "Tule listatuksi hakukoneissa",
  "overview-opportunities-item-2": "Kehitä palveluasi verkossa",
  "overview-opportunities-item-3": "Hyödynnä sosiaalista mediaa",
  "unit-month": "kk",
  "or": "tai",
  "websitesources-form-create-submit-text": "Lataa lähdekoodi"
}

export {
  translationFi,
  translationEn
}
