import showCaseEmobingo from './emobingo-showcase-video.webm'
import showCaseSmartsmear from './smartsmear-showcase.jpg'
import showCaseAvailability from './availability-showcase.jpg'

const ShowCaseData = [
  {
    title: {
      en: "Data Visualization UI",
      fi: "Datavisualisaatiotyökalu"
    },
    externalLink: "https://smear.avaa.csc.fi/",
    techList: ["Java", "React.js", "Docker", "OpenShift", "Quarkus"],
    imageSrc: showCaseSmartsmear,
    brief: {
      en: `
      I modernized the web user interface for SmartSMEAR, a data visualization tool for atmospheric data measured by the
      University of Helsinki.
      `,
      fi: `
      Modernisoin web käyttöliittymän SmartSMEAR-ohjelmalle, data visualisaatio työkalulle, jota käytetään
      ilmatieteiden tutkimusdatan visualisointiin Helsingin Yliopistossa.
      `
    }
  },
  {
    title: {
      en: "Product Availability UX",
      fi: "Tuotesaatavuuksien käyttäjäkokemus"
    },
    techList: ["React.js", "Sass"],
    imageSrc: showCaseAvailability,
    brief: {
      en: `
        I implemented the updated design of product availability presentation according to UX specifications.
      `,
      fi: `
        Toteutin päivitetyn designin tuotesaatavuuksien esittämiseen annettujen spesifikaatioiden mukaisesti.
      `
    }
  },
  {
    title: {
      en: "Song Bingo Party App",
      fi: "Kappalebingo bilesovellus"
    },
    externalLink: "https://www.emobingo.anteronweb.com/",
    techList: ["React.js", "AWS Lambda", "AWS S3", "Spotify API"],
    videoSrc: showCaseEmobingo,
    brief: {
      en: `
        I made a song bingo card app for a halloween party that we hosted. Although not a professional project, I'm
        proud of how it turned out, and at least our guests enjoyed the game.
      `,
      fi: `
        Tein bingokorttisovelluksen pitämiimme halloweenbileisiin. Vaikka tämä ei ole ammattiprojekti, olen tyytyväinen
        siitä, millainen siitä tuli, ja ainakin vieraamme arvostivat peliä.
      `
    }
  }
]

export default ShowCaseData
