import axios from 'axios'

// cognito url form: https://cognito-idp.{aws-region}.amazonaws.com
const COGNITO_URL = process.env.REACT_APP_COGNITO_URL
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID

export const signUp = async ({ email, password }) => {
  const headers = {
    'X-Amz-Target': 'AWSCognitoIdentityProviderService.SignUp',
    'Content-Type': 'application/x-amz-json-1.1'
  }

  const requestData = {
    ClientId: COGNITO_CLIENT_ID,
    Username: email,
    Password: password
  }
  try {
    const { status, data } = await axios.post(COGNITO_URL, requestData, { headers: headers })
    return { status, data: { id: data.UserSub, email: email } }
  } catch (e) {
    if (e.response) {
      const { status, data } = e.response
      return { status, data }
    } else {
      console.error(e)
    }
  }
}

export const initiateAuth = async ({ email, password }) => {
  const headers = {
    'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
    'Content-Type': 'application/x-amz-json-1.1'
  }

  const requestData = {
    ClientId: COGNITO_CLIENT_ID,
    AuthFlow: "USER_PASSWORD_AUTH",
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password
    }
  }
  try {
    const { status, data } = await axios.post(COGNITO_URL, requestData, { headers: headers })
    console.log(data)
    return {
      status,
      data: {
        authToken: data.AuthenticationResult.IdToken,
        accessToken: data.AuthenticationResult.AccessToken
      }
    }
  } catch (e) {
    if (e.response) {
      return e.response
    } else {
      console.error(e)
    }
  }
}

export const postConfirmSignUp = async (email, confirmationCode) => {
  const headers = {
    'X-Amz-Target': 'AWSCognitoIdentityProviderService.ConfirmSignUp',
    'Content-Type': 'application/x-amz-json-1.1'
  }

  const requestData = {
    ClientId: COGNITO_CLIENT_ID,
    Username: email,
    ConfirmationCode: confirmationCode
  }
  try {
    const { status, data } = await axios.post(COGNITO_URL, requestData, { headers: headers })
    return { status, data: { id: data.UserSub, email: email } }
  } catch (e) {
    if (e.response) {
      const { status, data } = e.response
      console.error(data.message)
      return { status, data }
    } else {
      console.error(e)
    }
  }
}

const ALLOWED_ATTRIBUTES = ["email", "name", "family_name", "phone_number"]

export const postGetUser = async (accessToken) => {
  const headers = {
    'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
    'Content-Type': 'application/x-amz-json-1.1'
  }

  const requestData = { AccessToken: accessToken }
  try {
    const { status, data } = await axios.post(COGNITO_URL, requestData, { headers: headers })
    const { Username, UserAttributes } = data
    const userAttributes = Object.fromEntries(UserAttributes.filter(a => ALLOWED_ATTRIBUTES.includes(a.Name))
                                                            .map(a => [a.Name, a.Value]))
    return { status, data: { id: Username, userAttributes } }
  } catch (e) {
    if (e.response) {
      const { status, data } = e.response
      console.error(data.message)
      return { status, data }
    } else {
      console.error(e)
    }
  }
}

export const postUpdateUserAttributes = async (accessToken, userAttributes) => {
  const headers = {
    'X-Amz-Target': 'AWSCognitoIdentityProviderService.UpdateUserAttributes',
    'Content-Type': 'application/x-amz-json-1.1'
  }
  const UserAttributes = Object.keys(userAttributes).map(k => ({ Name: k, Value: userAttributes[k] }))

  const requestData = { AccessToken: accessToken, UserAttributes }
  try {
    const { status } = await axios.post(COGNITO_URL, requestData, { headers: headers })
    return { status, data: { userAttributes } }
  } catch (e) {
    if (e.response) {
      const { status, data } = e.response
      console.error(data.message)
      return { status, data }
    } else {
      console.error(e)
    }
  }
}
