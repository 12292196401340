import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { sendDeleteOrganization, fetchOrganizationsForUser, postOrganization, putOrganizationsForUser } from '../../api/organizationAPI'

import { setShowRegisterOrganizationModal } from './appSlice.js'

/**
 * Register a new organization.
 */
export const register = createAsyncThunk(
  'organizations/register',
  async (organization, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const response = await postOrganization(organization, authToken)
      dispatch(setShowRegisterOrganizationModal(false))

      return response.data
    } catch (error) {
      if (!error.response) {
        throw error
      }
      console.error(error)
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

export const deleteOrganization = createAsyncThunk(
  'organizations/deleteOrganization',
  async (id, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const response = await sendDeleteOrganization(id, authToken)

      return response.data
    } catch (error) {
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

/**
 * Fetch assignments for the authenticated user.
 */
export const fetchOrganizationsForAuthenticatedUser = createAsyncThunk(
  'organizations/fetchOrganizationsForAuthenticatedUser',
  async (userData, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user
    const { id } = userData

    if (!authToken) {
      return rejectWithValue({message: "No auth token"})
    }

    try {
      const response = await fetchOrganizationsForUser(id, authToken)
      return response.data.organizations
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

export const updateForAuthenticatedUser = createAsyncThunk(
  'organizations/update',
  async (organization, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState()
    const { authToken } = user

    try {
      const response = await putOrganizationsForUser(organization, authToken)

      return response.data
    } catch (error) {
      console.error(error)
      return rejectWithValue({
        errors: error.response.data.errors,
        status: error.response.status,
        statusText: error.response.statusText
      })
    }
  }
)

/**
 * Redux state slice created with Redux toolkit.
 */
export const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizations: [],
    loading: true
  },
  reducers: {
    clearOrganizations: (state, action) => ({ ...state, organizations: [] }),
    changeOrganization: (state, action) => ({
      ...state,
      organizations: state.organizations.map(o => o.id === action.payload.id ? action.payload : o)
    })
  },
  extraReducers: {
    [fetchOrganizationsForAuthenticatedUser.pending]: (state, action) => ({ ...state, loading: true }),
    [fetchOrganizationsForAuthenticatedUser.fulfilled]: (state, action) => ({
      ...state,
      organizations: action.payload.map(o => ({ ...o })), loading: false }
    ),
    [fetchOrganizationsForAuthenticatedUser.rejected]: (state, action) => ({ ...state, loading: false }),
    [updateForAuthenticatedUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [updateForAuthenticatedUser.fulfilled]: (state, action) => ({
      ...state,
      organizations: state.organizations.map(o => o.id === action.payload.id ? action.payload : o),
      loading: false
    }),
    [updateForAuthenticatedUser.rejected]: (state, action) => ({
      ...state,
      loading: false
    }),
    [deleteOrganization.pending]: (state, action) => ({
      ...state,
      organizations: state.organizations.filter(o => o.id !== action.payload.id),
      loading: true
    }),
    [deleteOrganization.fulfilled]: (state, action) => ({
      ...state,
      organizations: state.organizations.filter(o => o.id !== action.payload.id),
      loading: false
    }),
    [register.pending]: (state, action) => ({...state, loading: true}),
    [register.fulfilled]: (state, action) => ({
      ...state,
      organizations: state.organizations.concat([action.payload]),
      loading: false
    }),
    [register.rejected]: (state, action) => ({ ...state, loading: false })
  }
})

export const { clearOrganizations, changeOrganization } = organizationsSlice.actions

export default organizationsSlice.reducer
