import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Stack } from 'react-bootstrap'

import { setShowLoginModal } from '../../store/slices/appSlice'

import LoginForm from '../login/LoginForm'
import SignUpForm from '../user/SignUpForm'

import './LoginModal.scss'

const LoginModal = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { cookieConsent, showLoginModal } = useSelector(state => state.appControl)
  const [activeForm, setActiveForm] = useState('login')

  if (!cookieConsent) {
    return null
  }

  return (
    <Modal centered show={showLoginModal} onHide={() => dispatch(setShowLoginModal(false))}>
      <Modal.Header closeButton>
        <Stack direction="horizontal" gap={3}>
          <span
            className="login-modal-header-item"
            onClick={ e => setActiveForm('login') }
          >{t("login-modal-login")}</span>
          <span className="vr" />
          <span
            className="login-modal-header-item"
            onClick={ e => setActiveForm('register') }
          >{t("login-modal-register")}</span>
        </Stack>
      </Modal.Header>
      <Modal.Body>
        {activeForm === 'login' ? <LoginForm /> : <SignUpForm onSignUpConfirmed={() => setActiveForm('login')} />}
      </Modal.Body>
    </Modal>
  )
}

export default LoginModal
