import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import './ShowCaseSection.scss'
import ShowCaseData from './ShowCaseData.js'
import i18n from '../../lang'

const ShowCaseVideoPlayer = ({src}) => {
  return (
    <video
      className="showcase-video-player"
      muted
      loop
      src={src}
      onMouseOver={event => event.target.play()}
      onMouseOut={event => event.target.pause()}
    />
  )
}

const ShowCaseImage = ({src}) => {
  return (
      <img className="showcase-figure" src={src} alt="show-case-image" />
  )
}

const ShowCaseFigure = () => {
  return (
    <div className="showcase-figure" />
  )
}

const ShowCaseProjectTechList = ({ techList, handleTechClick }) => (
  <ul className="showcase-tech-list">
    {techList.map((item, i) => (
      <li key={i} className="showcase-tech-list-item" onClick={e => handleTechClick(item)}>{item}</li>
    ))}
  </ul>
)

const ShowCaseProject = ({ title, externalLink, videoSrc, imageSrc, techList, brief, handleTechClick }) => {
  let thumbnail = null
  if (videoSrc) {
    thumbnail = <ShowCaseVideoPlayer src={videoSrc} />
  } else if (imageSrc) {
    thumbnail = <ShowCaseImage src={imageSrc} />
  } else {
    thumbnail = <ShowCaseFigure />
  }
  return (
    <Col className="showcase-card">
      {thumbnail}
      <Container className="showcase-video-info">
        <div className="showcase-video-header">
          <div className="showcase-title">
            {title}
            {externalLink && (
              <>
                {' '}
                <a href={externalLink} rel="noreferrer" target="_blank">
                  <FontAwesomeIcon className="showcase-card-link-icon" icon={faExternalLinkAlt} />
                </a>
              </>
            )
            }
          </div>
          <ShowCaseProjectTechList techList={techList} handleTechClick={handleTechClick} />
        </div>
        <div className="showcase-video-body">
          <p className="showcase-brief">{brief}</p>
        </div>
      </Container>
    </Col>
  )
}

const ShowCaseSection = () => {
  const [techFilter, setTechFilter] = useState([])
  const { t } = useTranslation()

  const toggleTechFilter = (tech) => {
    if(techFilter.includes(tech)) {
      setTechFilter(techFilter.filter(t => t !== tech))
    } else {
      setTechFilter(techFilter.concat(tech))
    }
  }

  const removeTechFilter = (tech) => setTechFilter(techFilter.filter(t => t !== tech))

  return (
    <section id="section-showcase" className="mx-auto">
      <h1>{t("showcase-header")}</h1>
      <ShowCaseProjectTechList className="showcase-tech-filter" techList={techFilter} handleTechClick={removeTechFilter} />
      <Row className="showcase-project-list">
        {ShowCaseData.map((s, i) => ({...s, key: i}))
            .filter(s => s.techList.filter(t => !techFilter.length || techFilter.includes(t)).length > 0)
            .map(showCase => (
              <ShowCaseProject
                key={showCase.key}
                title={showCase.title[i18n.language]}
                externalLink={showCase.externalLink}
                techList={showCase.techList}
                videoSrc={showCase.videoSrc}
                imageSrc={showCase.imageSrc}
                brief={showCase.brief[i18n.language]}
                handleTechClick={toggleTechFilter}
              />
            ))}
      </Row>
    </section>
  )
}

export default ShowCaseSection
