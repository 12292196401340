import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Container } from 'react-bootstrap'
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom'

import AppNav from '../appnav/AppNav'
import AppFooter from './AppFooter'
import CookieNotification from './CookieNotification'
import { ConsultingPage, DashboardPage, FrontPage, HostingPage, PrivacyPage } from '../pages'
import LoginModal from '../login/LoginModal'

import { init } from '../../store/slices/userSlice'
import { fetchOrganizationsForAuthenticatedUser } from '../../store/slices/organizationsSlice'
import { fetchForAuthenticatedUser } from '../../store/slices/assignmentsSlice'
import { recordScroll } from '../../store/slices/appSlice'

import OrganizationsPage from '../organization/OrganizationsPage'
import CreateOrganization from '../organization/CreateOrganization'
import ShowOrganization from '../organization/ShowOrganization'
import AssignmentsPage from '../assignment/AssignmentsPage'
import CreateAssignment from '../assignment/CreateAssignment'
import WebsiteForm from '../website/WebsiteForm'
import WebsitesPage from '../website/WebsitesPage'
import ShowWebsite from '../website/ShowWebsite'
import ShowAssignment from '../assignment/ShowAssignment'
import ShowUser from '../user/ShowUser'
import UserVerification from '../user/UserVerification'
import UserPasswordReset from '../user/UserPasswordReset'

import './App.scss'

const AppContent = () => (
        <Switch>
          <Route path="/dashboard/organizations/create">
            <DashboardPage pageComponent={<CreateOrganization />} />
          </Route>
          <Route path="/dashboard/organizations/:id">
            <DashboardPage pageComponent={<ShowOrganization />} />
          </Route>
          <Route path="/dashboard/organizations">
            <DashboardPage pageComponent={<OrganizationsPage />} />
          </Route>
          <Route path="/dashboard/assignments/create">
            <DashboardPage pageComponent={<CreateAssignment />} />
          </Route>
          <Route path="/dashboard/assignments/:id">
            <DashboardPage pageComponent={<ShowAssignment />} />
          </Route>
          <Route path="/dashboard/assignments">
            <DashboardPage pageComponent={<AssignmentsPage />} />
          </Route>
          <Route path="/dashboard/websites/create">
            <DashboardPage pageComponent={<Container className="p-5"><WebsiteForm /></Container>} />
          </Route>
          <Route path="/dashboard/websites/:id">
            <DashboardPage pageComponent={<ShowWebsite />} />
          </Route>
          <Route path="/dashboard/websites">
            <DashboardPage pageComponent={<WebsitesPage />} />
          </Route>
          <Route path="/dashboard">
            <Redirect to="/dashboard/assignments" />
          </Route>
          <Route path="/verify/:id">
            <UserVerification />
          </Route>
          <Route path="/users/:id/reset-password">
            <DashboardPage pageComponent={<UserPasswordReset />} />
          </Route>
          <Route path="/users/:id">
            <DashboardPage pageComponent={<ShowUser />} />
          </Route>
          <Route path="/hosting">
            <HostingPage />
          </Route>
          <Route path="/consulting">
            <ConsultingPage />
          </Route>
          <Route path="/privacy">
            <PrivacyPage />
          </Route>
          <Route path="/">
            <FrontPage />
          </Route>
        </Switch>
)

const App = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { messages } = useSelector(state => state.appControl)

  useEffect(() => {
    const initApp = async () => {
      const result = await dispatch(init())
      const { requestStatus } = result.meta
      if (requestStatus === "fulfilled") {
        if (result.payload.authToken) {
          dispatch(fetchOrganizationsForAuthenticatedUser())
          dispatch(fetchForAuthenticatedUser())
        }
      }

    }
    initApp()
    window.addEventListener("click", event => dispatch(recordScroll()), {once: true})
    window.addEventListener("scroll", event => dispatch(recordScroll()), {once: true})
  }, [dispatch])

  return (
    <Router>
      <CookieNotification />
      {messages.map((m, i) => <Alert key={i} variant="success">{t(m)}</Alert>)}
      <LoginModal />
      <AppNav />
      <AppContent />
      <AppFooter />
    </Router>
  )
}

export default App
