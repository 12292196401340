import { Formik } from 'formik'
import React, { useState } from 'react'
import { Accordion, Alert, Button, Form, Stack } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux'

import { register } from '../../store/slices/userSlice'
import UserPasswordFields from './UserPasswordFields'
import { VerifyForm } from '../login/LoginForm'

import './SignUpForm.scss'

const SignUpForm = ({ onSignUpConfirmed }) => {
  const dispatch = useDispatch()
  const { errors: userErrors, loading } = useSelector(state => state.user)
  const [ verify, setVerify ] = useState(false)
  const { t } = useTranslation()

  const handleCreateUser = async (formData) => {
    const result = await dispatch(register(formData))
    const { requestStatus } = result.meta
    if (requestStatus === "fulfilled") {
      setVerify(formData.email)
    }
  }

  const validateForm = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = 'Email required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }

    if (!values.password) {
      errors.password = 'Password required'
    } else if (!values.confirmPassword) {
      errors.confirmPassword = 'Password confirmation required'
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match'
    }

    return errors
  }

  if (!!verify) {
    return <VerifyForm email={verify} onVerified={onSignUpConfirmed} />
  }

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={handleCreateUser}
      validate={validateForm}
    >
      {({
        values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Accordion>
            <Stack gap={5}>
              <Form.Group controlId="accountCredentials">
                <Stack gap={2}>
                  <Form.Label>{t("user-form-email-label")}</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={handleChange}
                    isInvalid={!!touched.email && !!errors.email}
                    isValid={touched.email && !errors.email}
                    value={values.email}
                  />
                  {errors.email && touched.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                  <UserPasswordFields
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                </Stack>
              </Form.Group>
              <Form.Group controlId="userData">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>{t("user-form-personal-data-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Label>{t("user-form-first-name-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={handleChange}
                        isInvalid={!!touched.firstName && !!errors.firstName}
                        isValid={touched.firstName && !errors.firstName}
                        value={values.firstName}
                      />
                      <Form.Label>{t("user-form-last-name-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={handleChange}
                        isInvalid={!!touched.lastName && !!errors.lastName}
                        isValid={touched.lastName && !errors.lastName}
                        value={values.lastName}
                      />
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              <Form.Group controlId="userFormContact">
                <Accordion.Item eventKey="2">
                  <Accordion.Header>{t("user-form-contact-header")}</Accordion.Header>
                  <Accordion.Body>
                    <Stack gap={3}>
                      <Form.Label>{t("user-form-phone-label")}</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        isInvalid={!!touched.phone && !!errors.phone}
                        isValid={touched.phone && !errors.phone}
                        value={values.phone}
                      />
                    </Stack>
                  </Accordion.Body>
                </Accordion.Item>
              </Form.Group>
              {userErrors.map((message, index) => <Alert key={index} variant="danger">{t(message)}</Alert>)}
              <Button className="register-button" type="submit" disabled={loading}>{t("user-form-register")}</Button>
            </Stack>
          </Accordion>
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
